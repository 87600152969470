import React, {Component} from 'react';
import moment from "moment";
import './clan.css';
import EditClan from "../editclan";

class Clan extends Component {
    state = {
        user: {
            userId: 0,
            nick: "PLAYER 1",
            iconId: 1,
            country: "Russia",
        },
        clan: {
            id: 1,
            total: 17123,
            country: "Russia",
            register: "12.04.2021",
            waves: 1,
            exp: 0,
        },
        users: 1,
        uniq: "",
        max: 50,
        modal: false,
        settings: false,
        state: "info",
        clanBuffs: {},
        loading: false,
    };

    componentDidMount() {
        if(this.props.user !== undefined) {
            this.setState(() => {
                return {
                    user: this.props.user,
                    uniq: this.props.uniq,
                }
            }, () => {
                this.getClanInfo(this.state.user.clanId);
                this.getClanBuffs(this.state.user.clanId);
            })
        }
    };

    setThisState = (state = "") => {
        this.setState(() => {
            return {
                state: state,
            }
        });
    };

    getClanInfo = async (id = 0) => {
        if(id === 0) {
            console.log(id);
            return false;
        };
        id = parseInt(id);
        const url = `${this.props.server}/getClanInfo/` + id;
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
            });
            const json = await response.json();
            console.log(json.data);
            let data = JSON.parse(json.data);
            console.log(data);

            if(data !== undefined && data.id !== undefined) {
                this.setState(() => {
                    return {
                        clan: data,
                    }
                });
            }
        } catch (error) {
            this.getClanInfo(id);
        }
    };

    openModal = (open = true) => {
        this.setState(() => {
            return {
                modal: open,
            }
        });
    };

    openSettings = () => {
        this.setState(() => {
            return {
                settings: true,
            }
        });
    };


    closeEdit = () => {
        this.getClanInfo(this.state.user.clanId);
        this.setState(() => {
            return {
                settings: false,
            }
        });
    };

    leaveClan = async (data) => {
        console.log(data);
        if(data.userId === undefined || data.clanId === undefined) {
            console.log("fail: ", data);
            return false;
        }
        const url = `${this.props.server}/leaveClan/`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            console.log(json);
            let data2 = JSON.parse(json.data);
            console.log(data2);

            if(data2 !== undefined && json.ok === 1) {
                window.location.href = "/users/"+this.state.user.userId;
            }   else {

            }
        } catch (error) {
            console.log(error);
        }
    }


    getClanBuffs = async (id, force = false) => {
        const url = `${this.props.server}/clans/buffs/` + id;
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
            });
            const json = await response.json();
            console.log(json);
            let data = json.data;
            if(!force) {
                if(data !== undefined && Object.keys(data).length !== Object.keys(this.state.clanBuffs).length) {
                    this.setState(() => {
                        return {
                            clanBuffs: data,
                        }
                    }, () => {
                        console.log(this.state.clanBuffs);
                    });
                }
            }   else {
                if(data !== undefined) {
                    this.setState(() => {
                        return {
                            clanBuffs: data,
                        }
                    });
                }
            }
        } catch (error) {
            this.getClanBuffs(id, force);
        }
    };

    upgrade = async (buffId) => {
        if(buffId === undefined || this.state.clan.id === undefined) {
            console.log("fail: ", data);
            return false;
        }   else {
            this.setState(() => {
                return {
                    loading: true,
                }
            });
        }
        console.log()
        const data = {
            buffId: parseInt(this.state.clanBuffs[buffId].id),
            clanId: parseInt(this.state.clan.id),
            level: parseInt(this.state.clanBuffs[buffId].level)
        };
        const url = `${this.props.server}/clan/upgrade`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            console.log(json);
            let data2 = json;

            if(data2 !== undefined && data2.ok === 1) {
                this.getClanInfo(this.state.user.clanId);
                this.getClanBuffs(this.state.user.clanId, true);
            }
            this.setState(() => {
                return {
                    loading: false,
                }
            });
        } catch (error) {
            console.log(error);
            this.setState(() => {
                return {
                    loading: false,
                }
            });
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.user !== this.state.user) {
            this.setState(() => {
                return {
                    user: this.props.user,
                    uniq: this.props.uniq,
                }
            })
        }
    }


    render() {
        const {user, clan, users,max,settings,uniq, clanBuffs, loading} = this.state;
        const image = "/images/avatars/"+user.iconId+".png";
        const createLink = "/createClan/"+user.userId;
        const activityDays = moment().diff(moment(clan.register), 'days');
        const hours = moment().diff(moment(clan.register), 'hours');
        let activityText = "";
        if(activityDays > 0) {
            activityText = activityDays + " days ago";
        }   else if(hours > 0) {
            activityText = hours + " hours ago";
        }   else {
            activityText = "Less than an hour ago";
        }
        const clanImage = "/images/countries/"+(clan.country).toLowerCase()+".png";
        const admin = (parseInt(clan.ownerId) === parseInt(user.userId));

        let warningText = "Внимание, внимание! Спасибо за внимание";
        if(admin) {
            warningText = "Go out and disband the clan?"
        }   else {
            warningText = "Go out the clan?";
        }
        const modalClass = this.state.modal ? "warning-modal show" : "warning-modal";
        const modalBg = this.state.modal ? "warning-modal-bg show" : "warning-modal-bg";
        const countStars = parseInt(clan.exp / 5000);
        const exp = (clan.exp - (countStars*5000));
        const progress = (exp / (5000 / 100));
        const _this = this;
        return (
            <>
                {settings
                    ? <EditClan server={_this.props.server}   gems={_this.props.gems} uniq={_this.props.uniq} clan={clan} user={user} closeEdit={this.closeEdit}/>
                    :
                    <div className={"clan"+ (this.state.state === "upgrades" ? " upgrade" : "")}>
                        {parseInt(clan.ownerId) === parseInt(user.userId) ?
                            <div className="settings-clan">
                                <a href="#/" onClick={this.openSettings}>
                                    <img src="/images/button_settings.png"  alt=""/>
                                </a>
                            </div>
                            :
                            <></>
                        }
                        <div className={modalBg}></div>
                        <div className={modalClass}>
                            <span className="waning-modal">{warningText}</span>
                            <div className="warning-buttons">
                                <button className="ok" onClick={this.leaveClan.bind(this, {
                                    userId: user.userId,
                                    clanId: user.clanId
                                })}>OK
                                </button>
                                <button className="cancel" onClick={this.openModal.bind(this, false)}>Cancel</button>
                            </div>
                        </div>
                        <div className="close">
                            <a onClick={_this.props.setStatus.bind(_this, "")} href="#/"> <img
                                src="/images/button_x.png" alt=""/></a>
                            {/*<a  href={encodeURI('campdefense:{"action":"close"}')}> <img src="/images/button_x.png" alt=""/></a>*/}
                            {/*<a  href={encodeURI("campdefense:{action: 'close'}")}> <img src="/images/button_x.png" alt=""/></a>*/}
                        </div>

                        <div className="profile">
                            <div className="player">
                                <div className="icon">
                                    <img srcSet={image} alt=""/>
                                </div>
                                <div className="info">
                                    <div className="top">
                                        <span>#{clan.top}</span>
                                        <div>
                                            <img srcSet="/images/icon_wave.png" alt=""/>
                                            <span>{clan.waves ? clan.waves : 1}</span>
                                        </div>
                                        <div>
                                            <img srcSet="/images/icon_players.png" alt=""/>
                                            <span>{clan.users}/{max}</span>

                                        </div>
                                    </div>
                                    <div className="clan-name">
                                        <span>{clan.name ? clan.name : user.clanName}</span>
                                    </div>
                                </div>

                            </div>
                            <div className="menu">
                                <button className={"info" + (this.state.state === "info" ? " active" : "")} onClick={this.setThisState.bind(this,"info")}>
                                    <span>INFO</span>
                                    <img src="/images/right.png" alt=""/>
                                </button>
                                <button className="info disabled">
                                    <span>MEMBERS</span>
                                    <img src="/images/right.png" alt=""/>
                                </button>
                                <button className={"info " +  (this.state.state === "upgrades" ? " active" : "")}  onClick={this.setThisState.bind(this,"upgrades")}>
                                    <span>UPGRADES</span>
                                    <img src="/images/right.png" alt=""/>
                                </button>
                                <button className="info disabled">
                                    <span>REWARDS</span>
                                    <img src="/images/right.png" alt=""/>
                                </button>
                            </div>
                            <button className="clan-leave" onClick={this.openModal.bind(this, true)}>
                                <span>Leave clan</span>
                                <img src="/images/icon_leave.png" alt=""/>
                            </button>
                        </div>
                        <div className={"clan-info"}>
                            {this.state.state === "info" ?
                                <div className="infos">
                                    {clan.text !== "" ?
                                        <div className="label">
                                            <div className="desc">
                                                <span>{clan.text}</span>
                                            </div>
                                        </div>
                                        : ""}
                                    <div className="label">
                                        <div className="name">
                                            <span>Country</span>
                                        </div>
                                        <div className="text">
                                            <span className="clan-id">#{clan.top ? clan.top : clan.id}</span>
                                            <span className="clan-country">{clan.country}</span>
                                            <img src={clanImage} alt=""/>
                                        </div>
                                    </div>
                                    <div className="label">
                                        <div className="name">
                                            <span>Active</span>
                                        </div>
                                        <div className="text">
                                            <span className="clan-activity">{activityText}</span>
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="upgrades">
                                    <div className="progress-exp">
                                        <div className="progress-exp__count">
                                            <img className="progress-exp__icon" src="/images/buffs/Clan_EXP.png" alt=""/>
                                            <span>{countStars}</span>
                                        </div>
                                        <div className="progress-exp__value">
                                            <span>{exp} / 5000</span>
                                            <div className="progress-exp__bar">
                                                <div className="progress-exp__progress" style={{width: progress}}></div>
                                            </div>
                                        </div>

                                    </div>
                                    <h2>Available for distribution</h2>
                                    <img src="/images/buffs/available_bg.png" alt=""/>
                                    <div className="countStar progress-exp__count">
                                        <img className="progress-exp__icon" src="/images/buffs/Clan_EXP.png" alt=""/>
                                        <span>{countStars}</span>
                                    </div>
                                    <div className="elements">
                                        {Object.keys(clanBuffs).map(function (objectKey, i) {
                                            const buff = _this.state.clanBuffs[i];
                                            const image = "/images/buffs/"+buff.icon;
                                            let cls = "buff";
                                            if(buff.level !== null) {
                                                cls += " good";
                                            }
                                            return (
                                                <>
                                                    <button className="buff">
                                                        <span className="buff-user">
                                                            <div className="icon">
                                                            <img srcSet={image} alt=""/>
                                                        </div>
                                                        <div className="info">
                                                            <div className="buff-text">
                                                                <span>{buff.text}</span>
                                                            </div>
                                                            {admin ?
                                                                <div className="buff-levels">
                                                                    <span className="buff-level">Level {buff.level !== null ? buff.level : 0}</span>
                                                                    <span className="buff-current">+{buff.level !== null ? buff.level*buff.factor : 0}%</span>
                                                                    <span className="buff-factor"> > {buff.level !== null ? (buff.level+1)*buff.factor : buff.factor}%</span>
                                                                </div>
                                                                :
                                                                <div className="buff-levels">
                                                                    <span className="buff-level">Level {buff.level !== null ? buff.level : 0}</span>
                                                                    <span className="buff-factor">+{buff.level !== null ? buff.level*buff.factor : 0}%</span>
                                                                </div>
                                                            }
                                                        </div>
                                                        </span>
                                                        {admin ?
                                                            <a className={"ok" + ((countStars <= 0 || loading) ? " disabled" : "")}  onClick={_this.upgrade.bind(this, i)} href="#/">
                                                                <span>USE</span>
                                                                <div className="progress-exp__count">
                                                                    <img className="progress-exp__icon" src="/images/buffs/Clan_EXP.png" alt=""/>
                                                                    <span>{countStars}</span>
                                                                </div>
                                                            </a>
                                                            : ""
                                                        }

                                                    </button>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }
                </>
        );
    }
};

export default Clan;

