import React, {Component} from 'react';
import moment from "moment";
import './joinclan.css';

class Joinclan extends Component {
    state = {
        userId: 0,
        clan: {
            id: 1,
            total: 17123,
            country: "Russia",
            register: "12.04.2021",
        },
        max: 50,
        clanBuffs: {},
        showBuff: false,
        buffInfo: "",
        buffIcon: "",
        buffFactor: "",
        buffLevel: "",
        blockButton: false,
    };

    componentDidMount() {
        if(this.props.clan !== undefined) {
            this.setState(() => {
                return {
                    clan: this.props.clan,
                }
            }, () => {
                this.getClanBuffs();
            });
        };
        this.setState(() => {
            return {
                userId: this.props.userId,
            }
        })
    };

    blockButton = () => {
        if(this.state.blockButton === false) {
            this.setState(() => {
                return {
                    blockButton: true,
                }
            }, () => {
                setTimeout(() => {
                    this.setState(() => {
                        return {
                            blockButton: false,
                        }
                    })
                }, 5500)
            })

        }
    }

    getClanBuffs = async () => {
        const url = `${this.props.server}/clans/buffs/` + this.state.clan.id;
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
            });
            const json = await response.json();
            console.log(json);
            let data = json.data;
            if(data !== undefined && Object.keys(data).length !== Object.keys(this.state.clanBuffs).length) {
                this.setState(() => {
                    return {
                        clanBuffs: data,
                    }
                }, () => {
                    console.log(this.state.clanBuffs);
                });
            }
        } catch (error) {
            this.getClanBuffs();
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.clan !== this.state.clan) {
            this.setState(() => {
                return {
                    clan: this.props.clan,
                }
            })
        }
        if(prevProps.userId !== this.state.userId) {
            this.setState(() => {
                return {
                    userId: this.props.userId,
                }
            })
        }
    }

    joinClan = async (data) => {
        console.log("click");
        if(this.state.blockButton === false) {
            this.blockButton();
        }

        console.log(data);
        if(data.userId === undefined || data.clanId === undefined) {
            console.log("fail: ", data);
            return false;
        }
        const url = `${this.props.server}/joinClan/`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();
            console.log(json);
            let data2 = json;

            if(data2 !== undefined && data2.ok === 1) {
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            }
        } catch (error) {
            console.log(error);
        }
    };

    viewBuff = (index) => {
      let buff = this.state.clanBuffs[index];
        this.setState(() => {
            return {
                buffInfo: buff.text,
                buffIcon: "/images/buffs/"+buff.icon,
                buffFactor: buff.factor,
                buffLevel: buff.level !== null ? buff.level : 0,
                showBuff: true,
            }
        })
    };

    closeBuff = () => {
        this.setState(() => {
            return {
                showBuff: false,
            }
        })
    };


    render() {
        const {user, clan, users,max, userId, showBuff, buffIcon, buffInfo, clanBuffs,buffFactor, buffLevel} = this.state;
        const image = "/images/clans/"+clan.iconId+".png";
        const activityDays = moment().diff(moment(clan.register), 'days');
        const hours = moment().diff(moment(clan.register), 'hours');
        let activityText = "";
        if(activityDays > 0) {
            activityText = activityDays + " days ago";
        }   else if(hours > 0) {
            activityText = hours + " hours ago";
        }   else {
            activityText = "Less than an hour ago";
        }
        const clanImage = "/images/countries/"+(clan.country).toLowerCase()+".png";
        const _this = this;
        let cls = "buff-info";
        if(showBuff) {
            cls += " flex";
        }
        let buttonClass = "clan-join";
        if(this.state.blockButton) {
            buttonClass += " disabled";
        }
        return (
            <div className="joinclan">
                    <div className={cls}>
                        <img src={buffIcon} alt=""/>
                        <h2>{buffInfo}</h2>
                        <div className="level">
                            <span className="buff-level">Level {buffLevel}</span>
                            <span>+{buffFactor*buffLevel}%</span>
                        </div>
                        <a className="ok"  onClick={_this.closeBuff} href="#/">
                            <span>ОК</span>
                        </a>
                    </div>

                <div className="close">
                    <a onClick={_this.props.offActive} href="#/"> <img src="/images/button_x.png" alt=""/></a>
                </div>
                <div className="profile">
                    <div className="player">
                        <div className="icon">
                            <img srcSet={image} alt=""/>
                        </div>
                        <div className="info">
                            <div className="top">
                                <span>#{clan.top}</span>
                                <div>
                                    <img srcSet="/images/icon_wave.png" alt=""/>
                                    <span>{clan.waves? clan.waves : 1}</span>
                                </div>
                                <div>
                                    <img srcSet="/images/icon_players.png" alt=""/>
                                    <span>{clan.counts}/{max}</span>

                                </div>
                            </div>
                            <div className="clan-name">
                                <span>{clan.name}</span>
                            </div>
                        </div>

                    </div>
                    <div className="menu">
                        <button className="info active">
                            <span>INFO</span>
                            <img src="/images/right.png" alt=""/>
                        </button>
                        <button className="info disabled">
                            <span>MEMBERS</span>
                            <img src="/images/right.png" alt=""/>
                        </button>
                        <button className="info disabled">
                            <span>UPGRADES</span>
                            <img src="/images/right.png" alt=""/>
                        </button>
                        <button className="info disabled">
                            <span>REWARDS</span>
                            <img src="/images/right.png" alt=""/>
                        </button>
                    </div>
                    {_this.props.inClan === 1 ?
                    ""
                        :
                        clan.counts < max ?
                        <button className={buttonClass} onClick={this.joinClan.bind(this,{userId: userId, clanId: clan.id})}>
                        <span>Join clan</span>
                        <img src="/images/icon_join.png" alt=""/>
                        </button>
                        :
                        <button className="clan-join disabled clan-join-limit">
                        <span>Limit</span>
                        <img src="/images/icon_join.png" alt=""/>
                        </button>

                    }

                </div>
                <div className="clan-info">
                    <div className="buffs">
                        <div className="all-buffs">
                            <ul className="ul-buffs">
                                {Object.keys(_this.state.clanBuffs).map(function (objectKey, i) {
                                    const buff = _this.state.clanBuffs[i];
                                    const image = "/images/buffs/"+buff.icon;
                                    let cls = "buff";
                                    if(buff.level !== null) {
                                        cls += " good";
                                    }
                                    return (
                                        <>
                                            <li className={cls}>
                                                <a href="#/" onClick={_this.viewBuff.bind(this, i)}>
                                                    <img src={image} alt=""/>
                                                    <span>Level {buff.level !== null ? buff.level : 0}</span>
                                                </a>
                                            </li>
                                        </>
                                    )
                                })}

                            </ul>
                        </div>
                    </div>
                    <div className="infos">
                        {clan.text !== "" ?
                            <div className="label">
                                <div className="desc">
                                    <span>{clan.text}</span>
                                </div>
                            </div>
                        : ""}
                        <div className="label">
                            <div className="name">
                                <span>Country</span>
                            </div>
                            <div className="text">
                                <span className="clan-id">#{clan.top}</span>
                                <span className="clan-country">{clan.country}</span>
                                <img src={clanImage} alt=""/>
                            </div>
                        </div>
                        <div className="label">
                            <div className="name">
                                <span>Active</span>
                            </div>
                           <div className="text">
                               <span className="clan-activity">{activityText}</span>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Joinclan;

