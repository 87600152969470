import React, {Component} from 'react';
import Clan from "../clan";
import ClanChat from "../clanChat";
import './select.css';
import FindClan from "../findclan";
import CreateClan from "../createclan";

class Select extends Component {
    state = {
        state: "chat",
        user: {
            userId: 0,
            nick: "PLAYER 1",
            iconId: 1,
            country: "Russia",
        },
    };

    componentDidMount() {
        if(this.props.user !== undefined) {
            this.setState(() => {
                return {
                    user: this.props.user,
                }
            })
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.user !== this.state.user) {
            this.setState(() => {
                return {
                    user: this.props.user,
                }
            })
        }
    }


    render() {
        const {user} = this.state;
        const image = "/images/avatars/"+user.iconId+".png";
        const createLink = "/createClan/"+user.userId;
        const _this = this;
        return (
            <>
                {user.clanName !== undefined && user.clanName !== "" ?
                    this.props.status === "clanInfo" ?
                        <Clan server={_this.props.server}  gems={_this.props.gems} uniq={_this.props.uniq} user={user} setStatus={_this.props.setStatus} />
                        :
                        this.props.status === "topClans" ?
                            <FindClan server={_this.props.server} uniq={_this.props.uniq} setStatus={_this.props.setStatus} inClan={1}  />
                            :
                    <ClanChat server={_this.props.server}  uniq={_this.props.uniq} user={user} setStatus={_this.props.setStatus} />
                    :
                    <>
                        <div className="select">
                            <div className="close">
                                <a href={encodeURI('campdefense:{"action":"close"}')}> <img src="/images/button_x.png" alt="close"/></a>
                            </div>
                            <div className="profile">
                                <div className="player">
                                    <div className="icon">
                                        <img srcSet={image} alt="avatar"/>
                                    </div>
                                    <div className="nick">{user.nick}</div>
                                    <a href="#/" className="settings" onClick={_this.props.setStatus.bind(this,"editProfile")}><img src="/images/button_settings.png" alt="settings"/></a>
                                </div>
                                <div className="find-clan">
                                    <img src="/images/find.png" className="find_icon" alt="find"/>
                                    <a  className="" href="/#" onClick={_this.props.setStatus.bind(_this,"findClan")}>Find a clan</a>
                                    <img src="/images/right.png" className="right_icon" alt="icon_right"/>
                                </div>
                                <div className="find-clan">
                                    <img src="/images/icon_top.png" className="find_icon" alt="find"/>
                                    <a  className="" href="/#" onClick={_this.props.setStatus.bind(_this,"findClan")}>Top clans</a>
                                    <img src="/images/right.png" className="right_icon" alt="icon_right"/>
                                </div>
                                <div className="find-clan disabled">
                                    <img src="/images/icon_chat.png" className="find_icon" alt="find"/>
                                    <a className="disabled" href="/#" onClick={_this.props.setStatus.bind(_this,"globalChat")} disabled={true}>Global chat</a>
                                    <img src="/images/right.png" className="right_icon" alt="icon_right"/>
                                </div>
                            </div>
                            {user.clanName === undefined || user.clanName === "" ?
                                <div className="menu">
                                    <img src="/images/logo_clans.png" alt="clans"/>
                                    <div className="buttons">
                                        <button className="create" onClick={_this.props.setStatus.bind(_this,"createClan")}>
                                            <span>Create</span>
                                            <img src="/images/icon_create.png" alt="create"/>
                                        </button>
                                        <button className="find"   onClick={_this.props.setStatus.bind(_this,"findClan")}>
                                            <span>Find</span>
                                            <img src="/images/icon_find.png" alt="create"/>
                                        </button>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="menu">
                                        <h1>Вы в клане: <span>{user.clanName}</span></h1>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
                </>
        );
    }
};

export default Select;

