import React, {Component} from 'react';

import './app.css';
import moment from "moment";
import Cookies from 'universal-cookie';
import {Route, BrowserRouter, Switch, Redirect} from "react-router-dom";
import Register from "../register";
import CreateClan from "../createclan";
import FindClan from "../findclan";
import Select from "../select";
import Loading from "../loading/loading";
import EditProfile from "../editprofile";

class App extends Component {
    state = {
        error: false,
        message: "",
        userId: 0,
        userName: "",
        clanName: "",
        clanAdmin: 0,
        clanId: 0,
        iconId: 1,
        country: "",
        loading: true,
        status: "auth",
        uniq: "",
        version: 420,
        updated: undefined,
        gems: 0,
        server: "https://stereo7-dev.herokuapp.com",
    };

    setStatus = (status, e) => {
        if(e) {
            e.preventDefault();
        }
        this.setState(() => {
            return {
                status: status,
            }
        });
    };


    componentDidMount() {
        let servers = [
            'https://stereo7-dev.herokuapp.com',
        ];
        let ran = randomInteger(0, servers.length-1);

        this.setState(() => {
            return {
                server: servers[ran],
            }
        }, () => {
            console.log(this.state.server)
            console.log(ran)
        });

        function randomInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        let _this = this;
        window.setGems = function (count = 0) {
            if (_this.state.gems !== count) {
                _this.setState(() => {
                    return {
                        gems: count,
                    }
                });
            }
        };

        window.setClientData = function (count = 0, uniq = "", client_version= 1) {
            if (_this.state.gems !== count) {
                _this.setState(() => {
                    return {
                        gems: count,
                    }
                });
            }
            if (_this.state.uniq !== uniq) {
                _this.setState(() => {
                    return {
                        uniq: uniq,
                    }
                });
            }
            // alert("you version: "+client_version);
            // alert("minimal version: "+_this.state.version);
            if(client_version >= _this.state.version) {
                _this.setState(() => {
                    return {
                        updated: false,
                    }
                });
            }
        };


        window.setUniq = function (uniq = "") {
            if (_this.state.uniq !== uniq) {
                _this.setState(() => {
                    return {
                        uniq: uniq,
                    }
                }, () => {
                    alert("Ok, set uniq: " + _this.state.uniq);
                });
            }
        };
        setTimeout(() => {
            if(this.state.updated !== false) {
                this.setState(() => {
                    return {
                        updated: true,
                    }
                });
            }
        }, 7000)
    }

    createProfile = async (data = {}) => {
        if (data.nick === undefined) {
            return false;
        }
        data.userId = this.state.userId;

        console.log("send request newUser");
        const url = `${this.state.server}/newUser/`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const json = await response.json();
            console.log(json);
            if (json.ok === 1) {
                let data = JSON.parse(json.data);

                if (data !== undefined && data.nick !== undefined) {
                    this.setState(() => {
                        return {
                            userName: data.nick,
                            iconId: data.avatar,
                            country: data.country,
                        }
                    }, () => {
                        setTimeout(() => {
                            // window.location.reload();
                        }, 2000)
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };


    createClan = async (data = {}) => {
        if (data.nick === undefined) {
            return false;
        }
        data.userId = this.state.userId;

        const url = `${this.state.server}/newClan/`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const json = await response.json();
            console.log(json);
            if (json.ok === 1) {
                let data = JSON.parse(json.data);
                console.log(data);
                if (data !== undefined && data.nick !== undefined) {
                    this.setState(() => {
                        return {
                            clanName: data.nick,
                            clanAdmin: 1,
                        }
                    }, () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 2500)
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    User = ({match}) => {
        let userId = match.params.userId;
        this.UserCheck(userId);
        return (<></>);
    };

    getClan = async (userId) => {
        if (!userId) {
            this.setState(() => {
                return {
                    loading: false,
                }
            });
            return false;
        };
        const url = `${this.state.server}/getUserClan/` + userId;
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
            });
            const json = await response.json();
            console.log(json.data);
            let data = JSON.parse(json.data)[0];

            if (data !== undefined && data.name !== undefined) {
                console.log("okay, we here");
                this.setState(() => {
                    return {
                        clanName: data.name,
                        clanAdmin: (data.ownerId !== undefined && ("" + data.ownerId) === userId) ? 1 : 0,
                        clanId: data.id,
                    }
                }, () => {
                    this.setState(() => {
                        return {
                            loading: false,
                        }
                    });
                });
            }   else {

                setTimeout(() => {
                    this.setState(() => {
                        return {
                            loading: false,
                        }
                    });
                }, 3000)
            }
        } catch (error) {
            this.setState(() => {
                return {
                    loading: false,
                }
            });
        }
    };

    newUser = async () => {
        const url = `${this.state.server}/newUser/`;
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
            });
            const json = await response.json();
            console.log(json);
            console.log(json.data);
            console.log(json.insertId);
            if (json.data !== undefined) {
                let data = json.data;
                console.log(data);
                console.log(data.insertId);

                if (data !== undefined && data.insertId !== undefined) {
                    window.location.href = "/users/" + data.insertId;
                }   else {
                    this.newUser();
                }
            }


        } catch (error) {
            this.newUser();
        }
    }

    UserCheck = async (userId) => {
        if (userId !== this.state.userId) {
            this.setState(() => {
                return {
                    userId: userId,
                }
            })
        } else {
            return "Error";
        }
        const url = `${this.state.server}/checkUser/` + userId;
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
            });
            const json = await response.json();
            console.log(json);
            if (json.ok === 1) {
                let data = JSON.parse(json.data)[0];

                if (data !== undefined && data.name !== undefined) {
                    this.getClan(userId);
                    this.setState(() => {
                        return {
                            userName: data.name,
                            iconId: data.iconId,
                            country: data.country,
                        }
                    });
                } else if (data !== undefined && data.id !== undefined) {
                    this.setState(() => {
                        return {
                            userId: data.id,
                        }
                    }, () => {
                        this.setState(() => {
                            return {
                                loading: false,
                            }
                        });
                    });
                } else {
                    console.log("OMG ERROR");
                    this.setState(() => {
                        return {
                            userId: 0,
                        }
                    }, () => {
                        this.setState(() => {
                            return {
                                loading: false,
                            }
                        });
                    });
                }
            } else if (json.ok === 0) {
                this.setState(() => {
                    return {
                        loading: true,
                    }
                }, () => {
                    setTimeout(() => {
                        this.newUser();
                    }, 2000)
                });

            }
        } catch (error) {
            console.error('Ошибка:', error);
            console.error('Ошибка:', error.name);
            if (error.name === 'AbortError') { // обработать ошибку от вызова abort()

            } else {
                this.setState(() => {
                    return {
                        error: true,
                        message: "Ошибка получения данных, возможно сервер недоступен, попробуйте позже #876",
                    }
                });
            }
            return "Error";
        }
    };

    getInfo = () => {
        console.log("Click get info");
    };

    joinClan = () => {
        console.log("Click to join chat");
    };


    render() {
        const {userName, userId, clanName, clanAdmin, iconId, country, loading, status, clanId, uniq, gems, server,updated} = this.state;
        const user = {
            nick: userName, userId: userId, iconId: iconId, clanId: clanId,
            country: country, clanName: clanName, clanAdmin: clanAdmin
        };
        const _this = this;
        console.log(updated);

        return (
            <div className="app">
                <div className="dev-lable">dev</div>
                <BrowserRouter>
                    <Switch>
                        <Route path="/users/:userId" component={this.User}/>
                    </Switch>
                </BrowserRouter>
                {loading ? <Loading/> : ""}
                {updated ?
                    <div className="update">
                        <img src="/images/hero.png" alt=""/>
                        <h2>The clans got even cooler! Update the game to see for youself</h2>
                        <a className="client-update" href={encodeURI('campdefense:{"action":"market"}')}>
                            <span>UPDATE</span>
                        </a>
                    </div>
                    : ""}
                {
                    status === "findClan" ?
                        <FindClan server={server} uniq={uniq} getInfo={this.getInfo} setStatus={this.setStatus} joinClan={this.joinClan}
                                  userId={this.state.userId}/>
                        :
                        status === "editProfile" ?
                            <EditProfile server={server} gems={gems}  getInfo={this.getInfo} setStatus={this.setStatus}
                                      joinClan={this.joinClan}  uniq={uniq} user={user}/>
                            :
                            status === "createClan" ?
                                <CreateClan server={server} uniq={uniq} createClan={this.createClan} setStatus={this.setStatus}
                                            userId={this.state.userId} gems={gems}/>
                                :
                                <>
                                    {userName !== "" ?
                                        <Select server={server}  gems={gems} uniq={uniq} user={user} setStatus={this.setStatus}
                                                status={this.state.status}/> : ""}
                                    {userName === "" ?
                                        <Register server={server} createProfile={this.createProfile} setStatus={this.setStatus}/> : ""}
                                </>
                }

            </div>
        );
    }
};

export default App;

