import React, {Component} from 'react';
import './clanChat.css';
import moment from "moment";

class ClanChat extends Component {
    state = {
        user: {
            userId: 0,
            nick: "PLAYER 1",
            iconId: 1,
            country: "Russia",
        },
        clan: {
            id: 1,
            total: 17123,
            country: "Russia",
            register: "12.04.2021",
        },
        messages: [],
        msg: "",
        disabled: false,
    };

    componentDidMount() {
        if (this.props.user !== undefined) {
            this.setState(() => {
                return {
                    user: this.props.user,
                }
            }, () => {
                this.getClanInfo(this.state.user.clanId);
                this.getClanChat(this.state.user.clanId);
                setInterval(() => {
                    this.getClanChat(this.state.user.clanId);
                }, 25000)
            })
        }
    };

    updateClan = () => {
        this.getClanInfo(this.state.user.clanId);
    };

    getClanInfo = async (id = 0) => {
        if (id === 0) {
            console.log(id);
            return false;
        }
        ;
        id = parseInt(id);
        const url = `${this.props.server}/getClanInfo/` + id;
        try {
            const response = await fetch(url, {
                method: 'GET', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': this.state.token2,
                },
            });
            const json = await response.json();
            console.log(json.data);
            let data = json.data;
            console.log(data);

            if (data !== undefined && data.id !== undefined) {
                this.setState(() => {
                    return {
                        clan: data,
                    }
                });
            }
        } catch (error) {

        }
    };


    getClanChat = async (id = 0) => {
        if (id === 0) {
            console.log(id);
            return false;
        }
        ;
        id = parseInt(id);
        const url = `${this.props.server}/getClanChat`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({clanId: id}),
            });
            const json = await response.json();
            let data = json.data;
            console.log(data);
            if (data !== undefined && Object.keys(data).length !== Object.keys(this.state.messages).length) {
                console.log(data);
                this.setState(() => {
                    return {
                        messages: data,
                    }
                }, () => {
                    document.getElementsByClassName("chat_list")[0].scroll(0, 999999);
                });
            }
        } catch (error) {
            console.log(error);
        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user !== this.state.user) {
            this.setState(() => {
                return {
                    user: this.props.user,
                }
            })
        };
    }

    editMessage = (e) => {
        let text = e.target.value;
        this.setState(() => {
            return {
                msg: text,
            }
        });
    };

    sendMsg = async (e) => {
        e.preventDefault();
        this.setState(() => {
            return {
                disabled: true,
            }
        });
        if (this.state.msg.trim().length > 0) {
            const url = `${this.props.server}/sendChat`;
            try {
                const response = await fetch(url, {
                    method: 'POST', // или 'PUT'
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        clanId: this.state.clan.id,
                        userId: this.state.user.userId,
                        msg: this.state.msg.trim()
                    }),
                });
                const json = await response.json();
                console.log(json);
                if (json !== undefined && json.ok === 1) {
                    this.getClanChat(this.state.user.clanId);
                    this.setState(() => {
                        return {
                            msg: "",
                            disabled: false,
                        }
                    });
                }
            } catch (error) {

            }
        }
    };


    render() {
        const {user, clan, messages, disabled, msg} = this.state;
        const image = "/images/avatars/" + user.iconId + ".png";
        const createLink = "/createClan/" + user.userId;
        const clanImage = "/images/avatars/" + clan.iconId + ".png";
        const _this = this;
        return (
            <>
                <>
                    <div className="clanChat">
                        <div className="close">
                            <a href={encodeURI('campdefense:{"action":"close"}')}> <img src="/images/button_x.png"
                                                                                        alt=""/></a>
                        </div>
                        <div className="profile">
                            <div className="player">
                                <div className="icon">
                                    <img srcSet={image} alt=""/>
                                </div>
                                <div className="nick">{user.nick}</div>
                                <a href="/#" className="settings" onClick={_this.props.setStatus.bind(_this, "editProfile")}>
                                    <img src="/images/button_settings.png" alt=""/>
                                </a>
                            </div>
                            <button className="find-clan my-clan" onClick={_this.props.setStatus.bind(_this, "clanInfo")}>
                                <img srcSet={clanImage} className="find_icon" alt=""/>
                                <a href="#/" >{clan.name}</a>
                                <img src="/images/right.png" className="right_icon" alt=""/>
                            </button>
                            <button className="find-clan"  onClick={_this.props.setStatus.bind(_this, "topClans")}>
                                <img src="/images/icon_top.png" className="find_icon" alt=""/>
                                <a href="#/">TOP CLANS</a>
                                <img src="/images/right.png" className="right_icon" alt=""/>
                            </button>
                            <div className="find-clan disable">
                                <img src="/images/icon_chat.png" className="find_icon disable" alt=""/>
                                <a className="disabled" href="/#"
                                   onClick={_this.props.setStatus.bind(_this, "globalChat")} disabled={true}>GLOBAL
                                    CHAT</a>
                                <img src="/images/right.png" className="right_icon" alt=""/>
                            </div>
                        </div>
                        <div className="chat-box">
                            <ul className="chat_list">
                                {Object.keys(messages).length > 0 && Object.keys(messages).map(function (key, i) {
                                    const msg = messages[key];
                                    const userIcon = "/images/avatars/" + msg.iconId + ".png";
                                    return (
                                        <>
                                            {msg.join_clan === 1 ?
                                                <li className="join_clan">
                                                    <img src={userIcon} alt=""/>
                                                    <span>{msg.userName} joined</span>
                                                </li>
                                                : msg.leaving_clan === 1 ?
                                                    <li className="leaving_clan">
                                                        <img src={userIcon} alt=""/>
                                                        <span>{msg.userName} leaving clan</span>
                                                    </li>
                                                    :
                                                    msg.userId !== parseInt(user.userId) ?
                                                        <li className="message">
                                                            <div className="top">
                                                                <span className="top1">
                                                                    <img src={userIcon} alt=""/>
                                                                <span className="user-name-top1">{msg.userName}</span>
                                                                </span>
                                                                <span>{moment(msg.date).utc().format('HH:mm')} {moment(msg.date).format("Do MMMM")}</span>
                                                            </div>
                                                            <span>{msg.text}</span>
                                                        </li>
                                                        :
                                                        <li className="message self_message">
                                                            <div className="top">
                                                                <span className="top1">
                                                                    <img src={userIcon} alt=""/>
                                                                <span className="user-name-top1">{msg.userName}</span>
                                                                </span>
                                                                <span>{moment(msg.date).utc().format('HH:mm')} {moment(msg.date).format("Do MMMM")}</span>
                                                            </div>
                                                            <span>{msg.text}</span>
                                                        </li>
                                            }
                                        </>
                                    )
                                })}
                            </ul>
                            <form className="send-form">
                                <textarea maxLength="140" onChange={this.editMessage} placeholder="Write"
                                          value={msg}></textarea>
                                <button className={msg.trim().length > 0 ? "send" : "send disable"}
                                        disabled={!msg.trim().length > 0 || disabled} onClick={this.sendMsg}>
                                    <img src="/images/right.png" className="right_icon" alt=""/>
                                </button>
                            </form>
                        </div>
                    </div>
                </>
            </>
        );
    }
};

export default ClanChat;

