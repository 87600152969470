import React, {Component} from 'react';

import './createClan.css';

class EditClan extends Component {
    state = {
        nick: "",
        desc: "",
        country: "Russia",
        avatar: 1,
        error: 0,
        message: "",
        price: 25,
        gems: 0,
        checkNick: true,
        validName: true,
        validDesc: true,
        clan: {
            name: "",
            text: "",
            iconId: 1,
            country: "Russia",
        },
        user: {},
    };

    componentDidMount() {
        this.setState(() => {
            return {
                gems: this.props.gems,
                clan: this.props.clan,
                user: this.props.user,
                country: this.props.clan.country,
                avatar: this.props.clan.iconId,
            }
        });
        setTimeout(() => {
            document.querySelectorAll('img').forEach(function (node, i) {
                if (node.getAttribute('data-src') !== null) {
                    setTimeout(() => {
                        node.setAttribute('src', node.getAttribute('data-src'));
                    }, 70 * i)
                }
            });
        }, 1000)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.gems !== this.state.gems) {
            this.setState(() => {
                return {
                    gems: this.props.gems,
                }
            })
        }
        if (this.props.uniq !== this.state.uniq) {
            this.setState(() => {
                return {
                    uniq: this.props.uniq,
                }
            })
        }
    }


    setCountry = (data, e) => {
        e.preventDefault();
        console.log(data);
        this.setState(() => {
            return {
                country: data[0].toUpperCase() + data.substring(1),
            }
        })
    };

    setClan = (e) => {
        this.setState(() => {
            return {
                validDesc: false,
            }
        });
        if (this.state.gems < this.state.price) {
            this.setState(() => {
                return {
                    error: true,
                    message: "You don't have enough gems",
                    validDesc: false,
                }
            })
        }

        if (this.state.checkNick && this.state.gems >= this.state.price && this.state.validName && this.state.validDesc) {
            let data = {
                nick: this.state.nick,
                country: this.state.country,
                avatar: this.state.avatar,
                desc: this.state.desc,
                uniq: this.state.uniq,
                userId: this.state.user.userId,
            };
            console.log(data);
            this.updateClan(data);
        } else {
            this.setState(() => {
                return {
                    validDesc: false,
                }
            })
        }
    };


    updateClan = async (data = {}) => {
        if (data.nick === undefined || data.uniq === undefined) {
            return false;
        }

        const url = `${this.props.server}/updateClan/`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const json = await response.json();
            console.log(json);
            if (json.ok === 1) {
                let data = JSON.parse(json.data);
                console.log(data);
                if (data !== undefined && data.nick !== undefined) {
                    setTimeout(() => {
                        window.location.href = encodeURI('campdefense:{"action":"subtract", "count": 25}');
                        this.props.closeEdit();
                    }, 2000)
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    setAvatar = (data, e) => {
        e.preventDefault();
        this.setState(() => {
            return {
                avatar: +data,
            }
        })
    };


    checkNick = async (nick) => {
        if (nick === undefined) {
            return false;
        }
        if (nick === this.state.clan.name) {
            this.setState(() => {
                return {
                    checkNick: true,
                }
            });
            return true;
        }
        this.setState(() => {
            return {
                checkNick: false,
            }
        });

        const url = `${this.props.server}/checkClanName/`;
        try {
            const response = await fetch(url, {
                method: 'POST', // или 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({nick: nick.trim()}),
            });

            const json = await response.json();
            console.log(json);
            if (json.ok === 1) {
                console.log(json);
                this.setState(() => {
                    return {
                        checkNick: true,
                    }
                });
                return true;
            } else if (json.ok === 0) {
                this.setState(() => {
                    return {
                        error: true,
                        message: json.message,
                    }
                })
            }
        } catch (error) {
            console.log(error);
            this.setState(() => {
                return {
                    error: true,
                    message: error,
                }
            })
        }
        return false;
    };

    changeNick = (e) => {
        if (e.target !== null) {
            let nick = e.target.value;
            let re = /[^a-zA-ZА-Яа-я0-9\s()-_@#$%&]/g;
            nick = nick.replace(re, "");
            this.setState(() => {
                return {
                    nick: nick.trim(),
                }
            });
            if (nick.trim().length >= 4 && nick.trim().length <= 25) {
                this.setState(() => {
                    return {
                        nick: nick.trim(),
                        error: false,
                        validName: true,
                    }
                });
                let timer = setTimeout(() => {
                    this.checkNick(nick.trim());
                }, 1000);
            } else {
                this.setState(() => {
                    return {
                        error: true,
                        validName: false,
                        message: "Clan name must be no longer than 25 characters and no less than 4"
                    }
                })

            }

        }
    };

    changeDesc = (e) => {
        if (e.target !== null) {
            let nick = e.target.value;
            if (nick.trim().length >= 0 && nick.trim().length <= 50) {
                this.setState(() => {
                    return {
                        desc: nick.trim(),
                        error: false,
                        validDesc: true,
                    }
                });
            } else {
                this.setState(() => {
                    return {
                        error: true,
                        validDesc: false,
                        message: "Clan desc must be no longer than 50 characters"
                    }
                })

            }

        }
    };

    avaSwitch = (left, e) => {
        e.preventDefault();
        let ava = document.getElementsByClassName('avatars')[0];
        let activeAvatar = this.state.avatar;
        let elements = ava.getElementsByTagName('li');
        console.log(activeAvatar);
        if (!left) {
            if (activeAvatar < elements.length - 1) {
                elements[activeAvatar - 1].className = "";
                elements[activeAvatar].className = "active";
                ava.scroll(activeAvatar * 111, 0);
                this.setState(() => {
                    return {
                        avatar: this.state.avatar += 1,
                    }
                })
            } else {
                elements[activeAvatar - 1].className = "";
                elements[0].className = "active";
                ava.scroll(0, 0);
                this.setState(() => {
                    return {
                        avatar: 1,
                    }
                })
            }
        } else {
            if (activeAvatar > 1) {
                elements[activeAvatar].className = "";
                elements[activeAvatar - 1].className = "active";
                ava.scroll(((activeAvatar - 2) * 111), 0);
                this.setState(() => {
                    return {
                        avatar: activeAvatar -= 1,
                    }
                })
            } else {
                elements[activeAvatar].className = "";
                elements[elements.length - 2].className = "active";
                ava.scroll((elements.length - 1) * 111, 0);
                this.setState(() => {
                    return {
                        avatar: elements.length - 1,
                    }
                })
            }

        }
    };

    render() {
        const {gems,uniq, nick} = this.state;
        console.log(uniq);
        const _this = this;
        const backLink = "/users/" + this.props.userId;
        let classOk = "ok disabled";
        if (this.state.checkNick && this.state.validName && this.state.validDesc && gems >= this.state.price && !this.state.error) {
            classOk = "ok";
        }
        return (
            <div className="createClan">
                <h1>Edit clan</h1>
                <div className="close">
                    <a href="#/" onClick={this.props.closeEdit}> <img src="/images/button_x.png" alt=""/></a>
                </div>
                <div className="user-gold">
                    <img className="gems" src="/images/icon_gems.png" alt=""/>
                    <span>{gems}</span>
                    <a className="plus" href={encodeURI('campdefense:{"action":"buy"}')}>
                        <img className="plus" src="/images/icon_plus.png" alt=""/>
                    </a>

                </div>

                <div className="elements">
                    <div className="setNick">
                        <input type="text"
                               className="nick"
                               placeholder="Clan name"
                               defaultValue={this.state.clan.name}
                               value={nick}
                               onChange={this.changeNick}/>
                    </div>
                    <div className="error">
                        {this.state.error ? this.state.message : ""}
                    </div>
                    <div className="setCountry">
                        <h3>Country: <span>{this.state.country}</span></h3>
                        <div className="setCountries">
                            <ul className="countries">
                                <li className={this.state.country === "Russia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "russia")}>
                                        <img src="/images/countries/russia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Usa" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "usa")}>
                                        <img src="/images/countries/usa.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Thailand" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "thailand")}>
                                        <img data-src="/images/countries/thailand.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Philippines" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "philippines")}>
                                        <img data-src="/images/countries/philippines.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Poland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "poland")}>
                                        <img data-src="/images/countries/poland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Abkhazia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "abkhazia")}>
                                        <img src="/images/countries/abkhazia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Afghanistan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "afghanistan")}>
                                        <img src="/images/countries/afghanistan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Aland-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "aland-islands")}>
                                        <img src="/images/countries/aland-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Albania" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "albania")}>
                                        <img src="/images/countries/albania.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Algeria" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "algeria")}>
                                        <img src="/images/countries/algeria.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "American-samoa" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "american-samoa")}>
                                        <img src="/images/countries/american-samoa.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Andorra" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "andorra")}>
                                        <img src="/images/countries/andorra.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Angola" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "angola")}>
                                        <img src="/images/countries/angola.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Anguilla" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "anguilla")}>
                                        <img src="/images/countries/anguilla.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Antigua-and-barbuda" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "antigua-and-barbuda")}>
                                        <img src="/images/countries/antigua-and-barbuda.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Argentina" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "argentina")}>
                                        <img src="/images/countries/argentina.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Armenia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "armenia")}>
                                        <img src="/images/countries/armenia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Aruba" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "aruba")}>
                                        <img src="/images/countries/aruba.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Australia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "australia")}>
                                        <img src="/images/countries/australia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Austria" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "austria")}>
                                        <img src="/images/countries/austria.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Azerbaijan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "azerbaijan")}>
                                        <img src="/images/countries/azerbaijan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Azores-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "azores-islands")}>
                                        <img data-src="/images/countries/azores-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bahamas" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bahamas")}>
                                        <img data-src="/images/countries/bahamas.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bahrain" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bahrain")}>
                                        <img data-src="/images/countries/bahrain.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Balearic-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "balearic-islands")}>
                                        <img data-src="/images/countries/balearic-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bangladesh" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bangladesh")}>
                                        <img data-src="/images/countries/bangladesh.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Barbados" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "barbados")}>
                                        <img data-src="/images/countries/barbados.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Basque-country" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "basque-country")}>
                                        <img data-src="/images/countries/basque-country.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Belarus" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "belarus")}>
                                        <img data-src="/images/countries/belarus.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Belgium" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "belgium")}>
                                        <img data-src="/images/countries/belgium.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Belize" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "belize")}>
                                        <img data-src="/images/countries/belize.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Benin" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "benin")}>
                                        <img data-src="/images/countries/benin.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bermuda" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bermuda")}>
                                        <img data-src="/images/countries/bermuda.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bhutan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bhutan")}>
                                        <img data-src="/images/countries/bhutan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bolivia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bolivia")}>
                                        <img data-src="/images/countries/bolivia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bonaire" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bonaire")}>
                                        <img data-src="/images/countries/bonaire.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bosnia-and-herzegovina" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bosnia-and-herzegovina")}>
                                        <img data-src="/images/countries/bosnia-and-herzegovina.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Botswana" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "botswana")}>
                                        <img data-src="/images/countries/botswana.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Brazil" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "brazil")}>
                                        <img data-src="/images/countries/brazil.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "British-columbia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "british-columbia")}>
                                        <img data-src="/images/countries/british-columbia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "British-indian-ocean-territory" ? "active" : ""}>
                                    <a href="/#"
                                       onClick={_this.setCountry.bind(this, "british-indian-ocean-territory")}>
                                        <img data-src="/images/countries/british-indian-ocean-territory.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "British-virgin-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "british-virgin-islands")}>
                                        <img data-src="/images/countries/british-virgin-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Brunei" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "brunei")}>
                                        <img data-src="/images/countries/brunei.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Bulgaria" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "bulgaria")}>
                                        <img data-src="/images/countries/bulgaria.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Burkina-faso" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "burkina-faso")}>
                                        <img data-src="/images/countries/burkina-faso.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Burundi" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "burundi")}>
                                        <img data-src="/images/countries/burundi.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cambodia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cambodia")}>
                                        <img data-src="/images/countries/cambodia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cameroon" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cameroon")}>
                                        <img data-src="/images/countries/cameroon.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Canada" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "canada")}>
                                        <img data-src="/images/countries/canada.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Canary-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "canary-islands")}>
                                        <img data-src="/images/countries/canary-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cape-verde" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cape-verde")}>
                                        <img data-src="/images/countries/cape-verde.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cayman-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cayman-islands")}>
                                        <img data-src="/images/countries/cayman-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Central-african-republic" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "central-african-republic")}>
                                        <img data-src="/images/countries/central-african-republic.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ceuta" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ceuta")}>
                                        <img data-src="/images/countries/ceuta.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Chad" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "chad")}>
                                        <img data-src="/images/countries/chad.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Chile" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "chile")}>
                                        <img data-src="/images/countries/chile.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "China" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "china")}>
                                        <img data-src="/images/countries/china.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Christmas-island" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "christmas-island")}>
                                        <img data-src="/images/countries/christmas-island.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cocos-island" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cocos-island")}>
                                        <img data-src="/images/countries/cocos-island.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Colombia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "colombia")}>
                                        <img data-src="/images/countries/colombia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Comoros" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "comoros")}>
                                        <img data-src="/images/countries/comoros.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cook-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cook-islands")}>
                                        <img data-src="/images/countries/cook-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Corsica" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "corsica")}>
                                        <img data-src="/images/countries/corsica.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Costa-rica" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "costa-rica")}>
                                        <img data-src="/images/countries/costa-rica.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Croatia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "croatia")}>
                                        <img data-src="/images/countries/croatia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cuba" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cuba")}>
                                        <img data-src="/images/countries/cuba.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Curacao" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "curacao")}>
                                        <img data-src="/images/countries/curacao.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Cyprus" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "cyprus")}>
                                        <img data-src="/images/countries/cyprus.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Czech-republic" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "czech-republic")}>
                                        <img data-src="/images/countries/czech-republic.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Democratic-republic-of-congo" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "democratic-republic-of-congo")}>
                                        <img data-src="/images/countries/democratic-republic-of-congo.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Denmark" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "denmark")}>
                                        <img data-src="/images/countries/denmark.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Djibouti" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "djibouti")}>
                                        <img data-src="/images/countries/djibouti.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Dominica" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "dominica")}>
                                        <img data-src="/images/countries/dominica.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Dominican-republic" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "dominican-republic")}>
                                        <img data-src="/images/countries/dominican-republic.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "East-timor" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "east-timor")}>
                                        <img data-src="/images/countries/east-timor.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ecuador" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ecuador")}>
                                        <img data-src="/images/countries/ecuador.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Egypt" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "egypt")}>
                                        <img data-src="/images/countries/egypt.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "El-salvador" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "el-salvador")}>
                                        <img data-src="/images/countries/el-salvador.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "England" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "england")}>
                                        <img data-src="/images/countries/england.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Equatorial-guinea" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "equatorial-guinea")}>
                                        <img data-src="/images/countries/equatorial-guinea.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Eritrea" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "eritrea")}>
                                        <img data-src="/images/countries/eritrea.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Estonia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "estonia")}>
                                        <img data-src="/images/countries/estonia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ethiopia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ethiopia")}>
                                        <img data-src="/images/countries/ethiopia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "European-union" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "european-union")}>
                                        <img data-src="/images/countries/european-union.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Falkland-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "falkland-islands")}>
                                        <img data-src="/images/countries/falkland-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Faroe-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "faroe-islands")}>
                                        <img data-src="/images/countries/faroe-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Fiji" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "fiji")}>
                                        <img data-src="/images/countries/fiji.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Finland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "finland")}>
                                        <img data-src="/images/countries/finland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "France" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "france")}>
                                        <img data-src="/images/countries/france.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "French-polynesia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "french-polynesia")}>
                                        <img data-src="/images/countries/french-polynesia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Gabon" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "gabon")}>
                                        <img data-src="/images/countries/gabon.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Galapagos-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "galapagos-islands")}>
                                        <img data-src="/images/countries/galapagos-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Gambia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "gambia")}>
                                        <img data-src="/images/countries/gambia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Georgia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "georgia")}>
                                        <img data-src="/images/countries/georgia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Germany" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "germany")}>
                                        <img data-src="/images/countries/germany.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ghana" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ghana")}>
                                        <img data-src="/images/countries/ghana.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Gibraltar" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "gibraltar")}>
                                        <img data-src="/images/countries/gibraltar.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Greece" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "greece")}>
                                        <img data-src="/images/countries/greece.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Greenland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "greenland")}>
                                        <img data-src="/images/countries/greenland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Grenada" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "grenada")}>
                                        <img data-src="/images/countries/grenada.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Guam" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "guam")}>
                                        <img data-src="/images/countries/guam.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Guatemala" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "guatemala")}>
                                        <img data-src="/images/countries/guatemala.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Guernsey" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "guernsey")}>
                                        <img data-src="/images/countries/guernsey.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Guinea-bissau" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "guinea-bissau")}>
                                        <img data-src="/images/countries/guinea-bissau.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Guinea" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "guinea")}>
                                        <img data-src="/images/countries/guinea.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Haiti" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "haiti")}>
                                        <img data-src="/images/countries/haiti.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Hawaii" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "hawaii")}>
                                        <img data-src="/images/countries/hawaii.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Honduras" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "honduras")}>
                                        <img data-src="/images/countries/honduras.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Hong-kong" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "hong-kong")}>
                                        <img data-src="/images/countries/hong-kong.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Hungary" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "hungary")}>
                                        <img data-src="/images/countries/hungary.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Iceland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "iceland")}>
                                        <img data-src="/images/countries/iceland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "India" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "india")}>
                                        <img data-src="/images/countries/india.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Indonesia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "indonesia")}>
                                        <img data-src="/images/countries/indonesia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Iran" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "iran")}>
                                        <img data-src="/images/countries/iran.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Iraq" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "iraq")}>
                                        <img data-src="/images/countries/iraq.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ireland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ireland")}>
                                        <img data-src="/images/countries/ireland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Isle-of-man" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "isle-of-man")}>
                                        <img data-src="/images/countries/isle-of-man.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Israel" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "israel")}>
                                        <img data-src="/images/countries/israel.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Italy" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "italy")}>
                                        <img data-src="/images/countries/italy.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ivory-coast" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ivory-coast")}>
                                        <img data-src="/images/countries/ivory-coast.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Jamaica" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "jamaica")}>
                                        <img data-src="/images/countries/jamaica.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Japan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "japan")}>
                                        <img data-src="/images/countries/japan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Jersey" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "jersey")}>
                                        <img data-src="/images/countries/jersey.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Jordan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "jordan")}>
                                        <img data-src="/images/countries/jordan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Kazakhstan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "kazakhstan")}>
                                        <img data-src="/images/countries/kazakhstan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Kenya" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "kenya")}>
                                        <img data-src="/images/countries/kenya.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Kiribati" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "kiribati")}>
                                        <img data-src="/images/countries/kiribati.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Kosovo" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "kosovo")}>
                                        <img data-src="/images/countries/kosovo.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Kwait" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "kwait")}>
                                        <img data-src="/images/countries/kwait.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Kyrgyzstan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "kyrgyzstan")}>
                                        <img data-src="/images/countries/kyrgyzstan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Laos" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "laos")}>
                                        <img data-src="/images/countries/laos.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Latvia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "latvia")}>
                                        <img data-src="/images/countries/latvia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Lebanon" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "lebanon")}>
                                        <img data-src="/images/countries/lebanon.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Lesotho" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "lesotho")}>
                                        <img data-src="/images/countries/lesotho.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Liberia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "liberia")}>
                                        <img data-src="/images/countries/liberia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Libya" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "libya")}>
                                        <img data-src="/images/countries/libya.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Liechtenstein" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "liechtenstein")}>
                                        <img data-src="/images/countries/liechtenstein.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Lithuania" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "lithuania")}>
                                        <img data-src="/images/countries/lithuania.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Luxembourg" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "luxembourg")}>
                                        <img data-src="/images/countries/luxembourg.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Macao" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "macao")}>
                                        <img data-src="/images/countries/macao.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Madagascar" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "madagascar")}>
                                        <img data-src="/images/countries/madagascar.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Madeira" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "madeira")}>
                                        <img data-src="/images/countries/madeira.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Malasya" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "malasya")}>
                                        <img data-src="/images/countries/malasya.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Malawi" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "malawi")}>
                                        <img data-src="/images/countries/malawi.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Maldives" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "maldives")}>
                                        <img data-src="/images/countries/maldives.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Mali" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "mali")}>
                                        <img data-src="/images/countries/mali.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Malta" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "malta")}>
                                        <img data-src="/images/countries/malta.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Marshall-island" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "marshall-island")}>
                                        <img data-src="/images/countries/marshall-island.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Martinique" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "martinique")}>
                                        <img data-src="/images/countries/martinique.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Mauritania" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "mauritania")}>
                                        <img data-src="/images/countries/mauritania.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Mauritius" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "mauritius")}>
                                        <img data-src="/images/countries/mauritius.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Melilla" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "melilla")}>
                                        <img data-src="/images/countries/melilla.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Mexico" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "mexico")}>
                                        <img data-src="/images/countries/mexico.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Micronesia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "micronesia")}>
                                        <img data-src="/images/countries/micronesia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Moldova" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "moldova")}>
                                        <img data-src="/images/countries/moldova.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Monaco" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "monaco")}>
                                        <img data-src="/images/countries/monaco.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Mongolia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "mongolia")}>
                                        <img data-src="/images/countries/mongolia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Montenegro" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "montenegro")}>
                                        <img data-src="/images/countries/montenegro.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Montserrat" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "montserrat")}>
                                        <img data-src="/images/countries/montserrat.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Morocco" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "morocco")}>
                                        <img data-src="/images/countries/morocco.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Mozambique" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "mozambique")}>
                                        <img data-src="/images/countries/mozambique.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Myanmar" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "myanmar")}>
                                        <img data-src="/images/countries/myanmar.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Namibia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "namibia")}>
                                        <img data-src="/images/countries/namibia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Nato" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "nato")}>
                                        <img data-src="/images/countries/nato.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Nauru" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "nauru")}>
                                        <img data-src="/images/countries/nauru.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Nepal" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "nepal")}>
                                        <img data-src="/images/countries/nepal.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Netherlands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "netherlands")}>
                                        <img data-src="/images/countries/netherlands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "New-zealand" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "new-zealand")}>
                                        <img data-src="/images/countries/new-zealand.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Nicaragua" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "nicaragua")}>
                                        <img data-src="/images/countries/nicaragua.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Niger" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "niger")}>
                                        <img data-src="/images/countries/niger.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Nigeria" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "nigeria")}>
                                        <img data-src="/images/countries/nigeria.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Niue" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "niue")}>
                                        <img data-src="/images/countries/niue.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Norfolk-island" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "norfolk-island")}>
                                        <img data-src="/images/countries/norfolk-island.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "North-korea" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "north-korea")}>
                                        <img data-src="/images/countries/north-korea.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Northern-cyprus" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "northern-cyprus")}>
                                        <img data-src="/images/countries/northern-cyprus.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Northern-marianas-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "northern-marianas-islands")}>
                                        <img data-src="/images/countries/northern-marianas-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Norway" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "norway")}>
                                        <img data-src="/images/countries/norway.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Oman" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "oman")}>
                                        <img data-src="/images/countries/oman.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ossetia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ossetia")}>
                                        <img data-src="/images/countries/ossetia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Pakistan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "pakistan")}>
                                        <img data-src="/images/countries/pakistan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Palau" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "palau")}>
                                        <img data-src="/images/countries/palau.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Palestine" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "palestine")}>
                                        <img data-src="/images/countries/palestine.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Panama" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "panama")}>
                                        <img data-src="/images/countries/panama.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Papua-new-guinea" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "papua-new-guinea")}>
                                        <img data-src="/images/countries/papua-new-guinea.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Paraguay" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "paraguay")}>
                                        <img data-src="/images/countries/paraguay.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Peru" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "peru")}>
                                        <img data-src="/images/countries/peru.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Pitcairn-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "pitcairn-islands")}>
                                        <img data-src="/images/countries/pitcairn-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Portugal" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "portugal")}>
                                        <img data-src="/images/countries/portugal.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Puerto-rico" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "puerto-rico")}>
                                        <img data-src="/images/countries/puerto-rico.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Rapa-nui" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "rapa-nui")}>
                                        <img data-src="/images/countries/rapa-nui.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Republic-of-macedonia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "republic-of-macedonia")}>
                                        <img data-src="/images/countries/republic-of-macedonia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Republic-of-the-congo" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "republic-of-the-congo")}>
                                        <img data-src="/images/countries/republic-of-the-congo.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Romania" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "romania")}>
                                        <img data-src="/images/countries/romania.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Rwanda" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "rwanda")}>
                                        <img data-src="/images/countries/rwanda.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Saba-island" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "saba-island")}>
                                        <img data-src="/images/countries/saba-island.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sahrawi-arab-democratic-republic" ? "active" : ""}>
                                    <a href="/#"
                                       onClick={_this.setCountry.bind(this, "sahrawi-arab-democratic-republic")}>
                                        <img data-src="/images/countries/sahrawi-arab-democratic-republic.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Saint-kitts-and-nevis" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "saint-kitts-and-nevis")}>
                                        <img data-src="/images/countries/saint-kitts-and-nevis.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Samoa" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "samoa")}>
                                        <img data-src="/images/countries/samoa.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "San-marino" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "san-marino")}>
                                        <img data-src="/images/countries/san-marino.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sao-tome-and-prince" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sao-tome-and-prince")}>
                                        <img data-src="/images/countries/sao-tome-and-prince.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sardinia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sardinia")}>
                                        <img data-src="/images/countries/sardinia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Saudi-arabia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "saudi-arabia")}>
                                        <img data-src="/images/countries/saudi-arabia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Scotland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "scotland")}>
                                        <img data-src="/images/countries/scotland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Senegal" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "senegal")}>
                                        <img data-src="/images/countries/senegal.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Serbia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "serbia")}>
                                        <img data-src="/images/countries/serbia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Seychelles" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "seychelles")}>
                                        <img data-src="/images/countries/seychelles.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sicily" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sicily")}>
                                        <img data-src="/images/countries/sicily.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sierra-leone" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sierra-leone")}>
                                        <img data-src="/images/countries/sierra-leone.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Singapore" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "singapore")}>
                                        <img data-src="/images/countries/singapore.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sint-eustatius" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sint-eustatius")}>
                                        <img data-src="/images/countries/sint-eustatius.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sint-maarten" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sint-maarten")}>
                                        <img data-src="/images/countries/sint-maarten.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Slovakia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "slovakia")}>
                                        <img data-src="/images/countries/slovakia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Slovenia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "slovenia")}>
                                        <img data-src="/images/countries/slovenia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Solomon-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "solomon-islands")}>
                                        <img data-src="/images/countries/solomon-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Somalia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "somalia")}>
                                        <img data-src="/images/countries/somalia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Somaliland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "somaliland")}>
                                        <img data-src="/images/countries/somaliland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "South-africa" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "south-africa")}>
                                        <img data-src="/images/countries/south-africa.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "South-korea" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "south-korea")}>
                                        <img data-src="/images/countries/south-korea.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "South-sudan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "south-sudan")}>
                                        <img data-src="/images/countries/south-sudan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Spain" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "spain")}>
                                        <img data-src="/images/countries/spain.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sri-lanka" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sri-lanka")}>
                                        <img data-src="/images/countries/sri-lanka.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "St-barts" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "st-barts")}>
                                        <img data-src="/images/countries/st-barts.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "St-lucia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "st-lucia")}>
                                        <img data-src="/images/countries/st-lucia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "St-vincent-and-the-grenadines" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "st-vincent-and-the-grenadines")}>
                                        <img data-src="/images/countries/st-vincent-and-the-grenadines.png"
                                             alt="st-vincent-and-the-grenadines"/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sudan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sudan")}>
                                        <img data-src="/images/countries/sudan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Suriname" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "suriname")}>
                                        <img data-src="/images/countries/suriname.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Swaziland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "swaziland")}>
                                        <img data-src="/images/countries/swaziland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Sweden" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "sweden")}>
                                        <img data-src="/images/countries/sweden.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Switzerland" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "switzerland")}>
                                        <img data-src="/images/countries/switzerland.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Syria" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "syria")}>
                                        <img data-src="/images/countries/syria.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Taiwan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "taiwan")}>
                                        <img data-src="/images/countries/taiwan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tajikistan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tajikistan")}>
                                        <img data-src="/images/countries/tajikistan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tanzania" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tanzania")}>
                                        <img data-src="/images/countries/tanzania.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tibet" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tibet")}>
                                        <img data-src="/images/countries/tibet.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Togo" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "togo")}>
                                        <img data-src="/images/countries/togo.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tokelau" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tokelau")}>
                                        <img data-src="/images/countries/tokelau.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tonga" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tonga")}>
                                        <img data-src="/images/countries/tonga.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Transnistria" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "transnistria")}>
                                        <img data-src="/images/countries/transnistria.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Trinidad-and-tobago" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "trinidad-and-tobago")}>
                                        <img data-src="/images/countries/trinidad-and-tobago.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tunisia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tunisia")}>
                                        <img data-src="/images/countries/tunisia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Turkey" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "turkey")}>
                                        <img data-src="/images/countries/turkey.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Turkmenistan" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "turkmenistan")}>
                                        <img data-src="/images/countries/turkmenistan.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Turks-and-caicos" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "turks-and-caicos")}>
                                        <img data-src="/images/countries/turks-and-caicos.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tuvalu-1" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tuvalu-1")}>
                                        <img data-src="/images/countries/tuvalu-1.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Tuvalu" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "tuvalu")}>
                                        <img data-src="/images/countries/tuvalu.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Uganda" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "uganda")}>
                                        <img data-src="/images/countries/uganda.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Ukraine" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "ukraine")}>
                                        <img data-src="/images/countries/ukraine.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "United-arab-emirates" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "united-arab-emirates")}>
                                        <img data-src="/images/countries/united-arab-emirates.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "United-kingdom" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "united-kingdom")}>
                                        <img data-src="/images/countries/united-kingdom.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "United-nations" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "united-nations")}>
                                        <img data-src="/images/countries/united-nations.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Uruguay" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "uruguay")}>
                                        <img data-src="/images/countries/uruguay.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Uzbekistn" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "uzbekistn")}>
                                        <img data-src="/images/countries/uzbekistn.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Vanuatu" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "vanuatu")}>
                                        <img data-src="/images/countries/vanuatu.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Vatican-city" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "vatican-city")}>
                                        <img data-src="/images/countries/vatican-city.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Venezuela" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "venezuela")}>
                                        <img data-src="/images/countries/venezuela.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Vietnam" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "vietnam")}>
                                        <img data-src="/images/countries/vietnam.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Virgin-islands" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "virgin-islands")}>
                                        <img data-src="/images/countries/virgin-islands.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Wales" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "wales")}>
                                        <img data-src="/images/countries/wales.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Yemen" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "yemen")}>
                                        <img data-src="/images/countries/yemen.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Zambia" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "zambia")}>
                                        <img data-src="/images/countries/zambia.png" alt=""/>
                                    </a>
                                </li>
                                <li className={this.state.country === "Zimbabwe" ? "active" : ""}>
                                    <a href="/#" onClick={_this.setCountry.bind(this, "zimbabwe")}>
                                        <img data-src="/images/countries/zimbabwe.png" alt=""/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="setAvatar">
                            <h3>Avatar</h3>
                            <div className="ava">
                                <a href="/#" onClick={this.avaSwitch.bind(this, true)}>
                                    <img className="avaLeft" src="/images/right.png" alt=""/>
                                </a>
                                <ul className="avatars">
                                    <li className={this.state.avatar === 1 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 1)}>
                                            <img src="/images/clans/1.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 2 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 2)}>
                                            <img src="/images/clans/2.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 3 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 3)}>
                                            <img src="/images/clans/3.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 4 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 4)}>
                                            <img src="/images/clans/4.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 5 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 5)}>
                                            <img src="/images/clans/5.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 6 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 6)}>
                                            <img src="/images/clans/6.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 7 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 7)}>
                                            <img src="/images/clans/7.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 8 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 8)}>
                                            <img src="/images/clans/8.png" alt=""/>
                                        </a>
                                    </li>
                                    <li className={this.state.avatar === 9 ? "active" : ""}>
                                        <a href="/#" onClick={_this.setAvatar.bind(this, 9)}>
                                            <img src="/images/clans/9.png" alt=""/>
                                        </a>
                                    </li>
                                    <li></li>
                                </ul>
                                <a href="/#" onClick={this.avaSwitch.bind(this, false)}>
                                    <img className="avaRight" src="/images/right.png" alt=""/>
                                </a>

                            </div>
                        </div>
                        <div className="desc">
                            <h3>Description</h3>
                            <div className="setDesc">
                                <input type="text"
                                       className="nick"
                                       placeholder="Come up with a description of your clan"
                                       defaultValue={this.state.clan.text}
                                       onChange={this.changeDesc}/>
                            </div>
                        </div>
                    </div>
                    <a className={classOk} onClick={_this.setClan}
                       href={encodeURI('campdefense:{"action":"subtract", "count": 25}')}>
                        <img className="gems" src="/images/icon_gems.png" alt=""/>
                        <span>{this.state.price}</span>
                    </a>
                </div>
            </div>
        );
    }
};

export default EditClan;

