import React, {Component} from 'react';
import './loading.css';

export default class Loading extends Component {
    render() {
        return (
            <div className="loading">
                <div className="bar">

                </div>
                <img className="icon" src="/images/icon_gain_modules.png" alt=""/>
                <div className="text">Clan Buff Gain module increases damage from Modules</div>
            </div>
        )
    }
}